<template>
    <TransitionRoot appear :show="isOpen" as="template">
        <Dialog as="div" class="relative z-50" @close="close">
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <DialogOverlay class="fixed inset-0 bg-black bg-opacity-50" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div class="flex min-h-full items-center justify-center p-4 text-center">
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                    >
                        <DialogPanel class="w-full max-w-4xl rounded-2xl bg-white p-6 shadow-lg">
                            <img src="/assets/images/teamss_logo.png" class="h-8"/>
                            <div class="mb-4 mt-4 flex items-center justify-between">
                                <h3 class="text-lg font-medium text-gray-900">Complete your order</h3>
                                <button @click="close" class="text-primary hover:text-gray-500">
                                    <span class="sr-only">Close</span>
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>
                            <p class="text-start text-sm text-gray-500">Get all access and an extra 20% off when you subscribe annually</p>

                            <div class="mt-4 grid grid-cols-2 gap-4">
                                <div>
                                    <label for="membership" class="block text-start text-sm font-medium text-gray-700">Membership type</label>
                                    <select id="membership" v-model="selectedPlan"  @change="displayPlanDetails" class="mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm">
                                        <option v-for="plan in filteredPlans" :key="plan.id" :value="plan.id">{{ plan.display_name }}</option>
                                    </select>
                                    <div class="mt-5 text-sm text-gray-500">
                                        <p class="text-start">For most businesses that want to optimize web queries</p>
                                        <ul class="mt-2 space-y-1">
                                            <li v-for="feature in productStore.listPackage.split('\n')" :key="feature" class="flex items-center">
                                                <i class="fas fa-check-circle mr-2 text-primary"></i> {{ feature }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="mt-0">
                                    <label class="block text-start text-sm font-medium text-gray-700">Payment method</label>
                                    <div class="mt-2 space-y-2">
                                        <div
                                            v-for="method in displayedPaymentMethods"
                                            :key="method.id"
                                            :class="[
                                                'flex items-center rounded-xl bg-gray-100 px-2 py-2',
                                                { 'border-2 border-primary': selectedPaymentMethod === method.id + '+' + method.midtrans_value },
                                            ]"
                                        >
                                            <input
                                                v-model="selectedPaymentMethod"
                                                type="radio"
                                                :id="method.code"
                                                name="payment"
                                                :value=" method.id + '+' + method.midtrans_value"
                                                class="form-radio text-primary"
                                            />
                                            <label :for="method.code" class="ml-2 flex items-center">
                                                {{ method.display_name }}
                                                <img :src="'data:image/png;base64,' + method.image" :alt="method.display_name + ' logo'" class="ml-2 h-4" />
                                            </label>
                                        </div>
                                        <button v-if="showMoreButton" @click="toggleShowMore" class="text-primary underline">
                                            {{ showAllMethods ? 'Show less' : 'Show more' }}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-4 text-start text-xl font-bold text-gray-900">
                                {{ formatPrice(productStore.lstPrice) }} /{{ formatDate(productStore.typeSubscribe) }}
                            </div>

                            <div class="mt-4 text-start">
                                <a href="#" class="text-primary underline">Details</a>
                            </div>

                            <div class="mt-6 flex justify-end">
                             <button @click="submitOrder" :disabled="isLoading" class="w-1/2 rounded-md bg-primary px-4 py-2 text-white">
                                <span v-if="isLoading">Loading...</span>
                                <span v-else>Proceed to Payment</span>
                            </button>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="showPaymentModal" as="template">
        <Dialog as="div" class="relative z-[51]">
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <DialogOverlay class="fixed inset-0 bg-[black]/60" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div class="flex min-h-full items-center justify-center px-4 py-8">
                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                    >
                        <DialogPanel class="panel w-full max-w-lg overflow-hidden rounded-lg border-0 p-0 text-black dark:text-white-dark">
                            <div class="bg-[#fbfbfb] py-3 text-lg font-medium dark:bg-[#121c2c] ltr:pl-5 ltr:pr-[50px] rtl:pl-[50px] rtl:pr-5">
                                Confirm Payment
                            </div>
                            <div class="p-5">
                                <p class="mb-4 text-center font-semibold text-red-600">
                                    Please wait, do not close this window or navigate away until the process is complete.
                                </p>
                                <iframe :src="paymentRedirectUrl" width="100%" height="500px" frameborder="0"></iframe>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
<script lang="ts" setup>
    import { ref, computed, onMounted, watch } from 'vue';
    import { Dialog, DialogOverlay, DialogPanel, TransitionRoot, TransitionChild } from '@headlessui/vue';
    import {
        getSaleSubscriptionPlan,
        getProduct,
        createSaleOrder,
        confirmSaleOrder,
        createSaleOrderInvoice,
        confirmSaleOrderPayment,
        getPaymentMethod,
    } from '~/utils/apiService';
    import { useProductStore } from '@/stores/productStore';
    import { formatPrice, formatDate } from '@/utils/numberFormat';
    import { useAuthStore } from '@/stores/authStore';
    const authStore = useAuthStore();
    const productStore = useProductStore();
    const paymentMethods = ref([]);
    const showAllMethods = ref(false);
    const productSelected = ref({});
    const router = useRouter();
    const selectedProduct = ref<string | null>(null);
    const selectedPlan = ref<string | null>(null);
    const selectedPlanDetails = ref<any>(null);
    const selectedPaymentMethod = ref<any>(null);
    const products = ref([]);
    const plans = ref([]);
    const payment_method = ref([]);
    const filteredPlans = ref([]);
    const isLoading = ref(false);
    const showPaymentModal = ref(false);
    const isOpen = ref(false);
    const isUser = ref('');
    const paymentRedirectUrl = ref('');
    const props = defineProps({
        isOpen: {
            type: Boolean,
            required: true,
        },
        isUser:{
            type: String,
            required: true,
        },
        product: {
            type: Object,
            required: true,
        },
    });

    const emit = defineEmits(['close']);

    const close = () => {
        isOpen.value =false
        emit('close');
    };

    const proceedToPayment = () => {
        // Handle payment process
    };

    const fetchPaymentMethod = async () => {
        try {
            const response = await getPaymentMethod({
                company_id: 0,
                limit: 0,
                offset: 0,
                name: '',
                midtrans_value: '',
                payment_method_id: 0,
            });
            productSelected.value = props.product;

            paymentMethods.value = response.data;
        } catch (error) {
            console.error('Failed to fetch payment methods:', error);
        }
    };

    const displayedPaymentMethods = computed(() => {
        return showAllMethods.value ? paymentMethods.value : paymentMethods.value.slice(0, 6);
    });

    const showMoreButton = computed(() => {
        return paymentMethods.value.length > 6;
    });

    const toggleShowMore = () => {
        showAllMethods.value = !showAllMethods.value;
    };

    let baseUrl = '';

    if (process.client) {
        baseUrl = window.location.origin;
    }
    let successURL = `${baseUrl}/success`;
    if(props.isUser == 'yes'){
        successURL = `${baseUrl}/admin/apps/user`;
    }

    definePageMeta({
        layout: 'auth-layout',
    });

    const fetchProducts = async () => {
        try {
            const params = {
                company_id: 1,
                limit: 0,
                name: '',
                offset: 0,
                product_id: 0,
            };
            const response = await getProduct(params);
            products.value = response.data;
        } catch (error) {
            console.error('Failed to fetch products', error);
        }
    };

    const fetchPlans = async () => {
        try {
            const response = await getSaleSubscriptionPlan({
                company_id: 1,
                plan_id: 0,
                limit: 0,
                offset: 0,
                name: '',
            });
            plans.value = response.data;
            filterPlansByProduct();
        } catch (error) {
            console.error('Failed to fetch plans:', error);
        }
    };

    const filterPlansByProduct = async () => {
        await productStore.loadProduct();
        const product = productStore;
        if (product) {
            const productIdStr = product.id.toString();
            filteredPlans.value = plans.value.filter((plan) =>
                plan.product_subscription_pricing_ids.some((id:any) => id.product_id.split('_')[0] === productIdStr)
            );
        }
    };

    const initializeData = async () => {
        await productStore.loadProduct();
        await fetchPaymentMethod();
        await fetchPlans();
        await filterPlansByProduct();
    };

    onMounted(() => {
        initializeData();
        console.log( props.isUser , 'isUser')
    });

    watch(() => props.isOpen, (newVal) => {
        console.log(props, 'props')
        if (newVal) {
           console.log( props.isUser , 'isUser')
            isOpen.value =true

            initializeData();
        }
    });

    const displayPlanDetails = () => {
        fetchPaymentMethod();
        selectedPlanDetails.value = products.value.find((plan: any) => plan.id === selectedProduct.value);
    };

    const formatPrice = (price: any) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        }).format(price);
    };

    const planDetailsList = computed(() => {
        return selectedPlanDetails.value ? selectedPlanDetails.value.list_package.split('\n') : [];
    });

    const generateClientOrderRef = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const uniqueId = Math.floor(Math.random() * 10000)
            .toString()
            .padStart(4, '0');

        return `INV/${year}${month}${day}/${hours}${minutes}${seconds}/${uniqueId}`;
    };

    function addMonths(date: Date, months: number): Date {
        const d = new Date(date);
        d.setMonth(d.getMonth() + months);
        return d;
    }

    const currentDate: Date = new Date();
    const dateOrder: string = currentDate.toISOString().slice(0, 10);
    const endDate: string = addMonths(currentDate, 1).toISOString().slice(0, 10);

    const submitOrder = async () => {
        if (!productStore.id) {
            showMessage('Please select a Product to complete your order.', 'danger');
            return;
        }
        if (!selectedPlan.value) {
            showMessage('Please select a plan to complete your order.', 'danger');
            return;
        }
        if (!selectedPaymentMethod.value) {
            showMessage('Please select a Payment Method to complete your order.', 'danger');
            return;
        }

        const payment_method_data = selectedPaymentMethod.value.split('+');
        const clientOrderRef = generateClientOrderRef();
        isLoading.value = true;

        try {
            const response = await createSaleOrder({
                client_order_ref: clientOrderRef,
                company_id: 1,
                date_order: dateOrder,
                end_date: endDate,
                order_line: [{ product_id: productStore.id, qty: 1 }],
                partner_id: parseInt(authStore.partnerId),
                payment_term_id: 1,
                plan_id: parseInt(selectedPlan.value),
            });

            const response2 = await confirmSaleOrder({
                company_id: parseInt(authStore.companyId),
                limit: 0,
                offset: 0,
                sale_id: parseInt(response.data.sale_id),
            });

            const response3 = await createSaleOrderInvoice({
                company_id: parseInt(authStore.companyId),
                limit: 0,
                offset: 0,
                sale_id: parseInt(response.data.sale_id),
            });

            const response4 = await confirmSaleOrderPayment({
                company_id: parseInt(authStore.companyId),
                limit: 0,
                offset: 0,
                invoice_id: parseInt(response3.data.invoice_id.split('_')[0]),
                midtrans_value: payment_method_data[1],
                payment_method_id: parseInt(payment_method_data[0]),
                sale_id: parseInt(response.data.sale_id),
                callback: successURL,
            });
            sessionStorage.setItem('payment_id', response4.data.payment_id.split('_')[0]);
            paymentRedirectUrl.value = response4.data.redirect_url;
            showPaymentModal.value = true;
            isOpen.value = false;
            showMessage('Order created successfully.', 'success');
        } catch (error) {
            console.error('Failed to create sale order:', error);
            showMessage('Failed to create order. Please try again.', 'danger');
        } finally {
            isLoading.value = false;
        }
    };

    const closePaymentModal = () => {
        showPaymentModal.value = false;
    };
</script>

<style scoped>
    /* Custom styles to match the design */
    .form-radio {
        border-radius: 50%;
        border: 1px solid #d1d5db; /* Example border color */
        width: 16px;
        height: 16px;
    }

    .form-radio:checked {
        border: 6px solid primary; /* Example checked color */
    }
</style>
